import React, { useEffect, useRef, useState } from 'react';

type ScrollHandlerProps = {
  onScroll: (number) => void;
  prevent: boolean;
};

const ScrollHandler = ({ onScroll, prevent }: ScrollHandlerProps) => {
  // for desktop use
  const handleScroll = (e: WheelEvent) => {
    if (prevent) {
      // console.log('event', e.deltaY);
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      // const deltaY = currentScrollTop - previousScrollTop.current;
      // console.log('current position:', currentScrollTop);
      // previousScrollTop.current = currentScrollTop;

      e.preventDefault();
      onScroll(e.deltaY);
    }
  };

  // for mobile
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const startXRef = useRef(startX);
  const startYRef = useRef(startY);

  const handleTouchStart = (e: TouchEvent) => {
    // console.log('start', e, e.touches[0].clientX, e.touches[0].clientY);
    setStartX(e.touches[0].clientX);
    startXRef.current = e.touches[0].clientX;
    setStartY(e.touches[0].clientY);
    startYRef.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e: TouchEvent) => {
    // console.log('startX', startXRef.current, 'startY', startYRef.current);
    if (startXRef.current === 0 || startYRef.current === 0) return;
    // console.log('move', e);
    const deltaX = e.touches[0].clientX - startXRef.current;
    const deltaY = e.touches[0].clientY - startYRef.current;

    // console.log('Delta x, y', deltaX, deltaY);

    if (prevent) {
      // console.log('event', e);
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      // console.log('current position:', currentScrollTop);
      // previousScrollTop.current = currentScrollTop;

      e.preventDefault();
      // revert delta for mobile touch as it is opposite
      onScroll(-deltaY);
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false });
    window.addEventListener('touchstart', handleTouchStart, { passive: false });
    window.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleScroll);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [onScroll]);

  return null;
};

export default ScrollHandler;
