import { motion, useInView, useMotionValue, useMotionValueEvent, useTransform } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import ScrollHandler from '../ScrollHandler/ScrollHandler';
import { useScrollTrigger } from '../ScrollHandler/ScrollTriggerProvider';
import styles from './HomeSection.module.scss';

const marginTop = 80; // according to nav max height for desktop
const maxScrollPositionPerSection = 2000;

const HomeSection = ({ id, t }) => {
  const router = useRouter();
  const { locale } = router;

  const [dashboardURLLogin, setDashboardURLLogin] = useState('https://app.whiteclover.uk/dashboard/login');
  const [dashboardURLRegister, setDashboardURLRegister] = useState(
    'https://app.whiteclover.uk/dashboard/create-account',
  );

  useEffect(() => {
    setDashboardURLLogin(
      window.location.host.includes('localhost')
        ? 'http://localhost:3000/dashboard/login'
        : `https://app.${window.location.host}/dashboard/login`,
    );

    setDashboardURLRegister(
      window.location.host.includes('localhost')
        ? 'http://localhost:3000/dashboard/create-account'
        : `https://app.${window.location.host}/dashboard/create-account`,
    );
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);
  const [mobile, setMobile] = useState(false);
  const [tablet, setTablet] = useState(false);
  const [desktop, setDesktop] = useState(false);

  const refTwo = useRef(null);
  const isInViewSection = useInView(refTwo, { amount: 'all', once: false });

  const [preventScroll, setPreventScroll] = useState(false);

  const [scrollHeight, setScrollHeight] = useState(50000);
  const [scrollPosition, setScrollPosition] = useState(0);
  // const scrollYProgress = useMotionValue(scrollPosition);
  const scrollYProgress = useScrollTrigger();
  const [deltaY, setDeltaY] = useState(0);

  const mainImageScale = useTransform(
    scrollYProgress,
    [0, maxScrollPositionPerSection * 7],
    desktop ? [1, 0.5] : [1, 1],
  ); // Modify the input and output ranges as needed
  const [maxWidth, setMaxWidth] = useState(1000);
  const coupleComments1 = useTransform(scrollYProgress, [0, maxScrollPositionPerSection * 5], [-1000, 0]); // Modify the input and output ranges as needed
  const coupleComments2 = useTransform(scrollYProgress, [0, maxScrollPositionPerSection * 5], [1000, 0]); // Modify the input and output ranges as needed

  const setWidth = () => {
    setMaxWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', setWidth);
    return () => window.removeEventListener('resize', setWidth);
  }, []);

  useMotionValueEvent(mainImageScale, 'change', (latest) => {
    console.log('latest-home', latest);
  });

  const handleScroll = (deltaY) => {
    setDeltaY(deltaY);
    console.log('ela1', refTwo.current.getBoundingClientRect());
    console.log('ela2', window.scrollY);
    setScrollHeight(window.scrollY);
    console.log('ela3', deltaY);
    const newPosition = scrollPosition + deltaY;
    setScrollPosition(newPosition);
    console.log(`New position is ${newPosition}`);

    if (newPosition <= 0) {
      console.log('MIKROTERO TOU MIDEN');
      setPreventScroll(false);
      document.body.style.overflow = 'scroll';
      setScrollPosition(0);
    }

    if (newPosition <= maxScrollPositionPerSection) setActiveIndex(0);
    else if (newPosition > maxScrollPositionPerSection && newPosition <= 2 * maxScrollPositionPerSection)
      setActiveIndex(1);
    else if (newPosition > 2 * maxScrollPositionPerSection && newPosition <= 3 * maxScrollPositionPerSection)
      setActiveIndex(2);
    else if (newPosition > 3 * maxScrollPositionPerSection && newPosition <= 4 * maxScrollPositionPerSection)
      setActiveIndex(3);
    else if (newPosition > 4 * maxScrollPositionPerSection && newPosition <= 5 * maxScrollPositionPerSection)
      setActiveIndex(4);

    if (newPosition >= maxScrollPositionPerSection * 5) {
      console.log(`MEGALITERO TOU ${5 * maxScrollPositionPerSection}`);
      setPreventScroll(false);
      document.body.style.overflow = 'scroll';
      setScrollPosition(maxScrollPositionPerSection * 5);
    }
    // Handle scroll position updates here, e.g., update other components or state
  };

  // useEffect(() => {
  //   // scrollYProgress.set(scrollPosition);
  //   // console.log('scrollYProgress updated');
  //   console.log('scrollYProgress', scrollYProgress);
  // }, [scrollYProgress]);

  const scrollToPosition = (y: number) => {
    let scrollToCalled = false;
    const onScroll = () => {
      if (scrollToCalled) {
        window.scrollTo({
          top: isNaN(refTwo.current.offsetTop - marginTop) ? 0 : refTwo.current.offsetTop - marginTop,
        });
        // Prevent the default scroll behavior
        // setTimeout(() => window.removeEventListener('scroll', onScroll), 500);
        window.removeEventListener('scroll', onScroll);
        document.body.style.overflow = 'hidden';
        setPreventScroll(isInViewSection);
      }
    };

    try {
      // Attach the scroll event listener to the window
      window.addEventListener('scroll', onScroll);

      // Call the scrollTo method with the smooth behavior
      requestAnimationFrame(() =>
        window.scrollTo({
          top: y,
          behavior: 'auto',
        }),
      );

      // Set the flag to indicate that the scrollTo method was called
      scrollToCalled = true;
    } catch (error) {
      console.log('Error in scrollToPosition: ', error);
      // Detach the scroll event listener from the window
      window.removeEventListener('scroll', onScroll);
    }
  };

  // useEffect(() => {
  //   if (desktop) {
  //     console.log('Animated Section is in view: ', isInViewSection);
  //
  //     if (isInViewSection) {
  //       console.log('REF TWO CURRENT:', refTwo.current);
  //       scrollToPosition(isNaN(refTwo.current.offsetTop - marginTop) ? 0 : refTwo.current.offsetTop - marginTop);
  //     }
  //     setPreventScroll(isInViewSection);
  //   }
  // }, [isInViewSection]);

  const checkScreenSize = () => {
    if (isMobile) {
      setMobile(true);
      if (isTablet) setTablet(true);
      setDesktop(false);
    } else {
      setMobile(false);
      setTablet(false);
      setDesktop(true);
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const onToggle = (index) => {
    setActiveIndex(index === activeIndex ? null : index);

    if (index === 0) setScrollPosition(maxScrollPositionPerSection - maxScrollPositionPerSection / 2);
    else if (index === 1) setScrollPosition(2 * maxScrollPositionPerSection - maxScrollPositionPerSection / 2);
    else if (index === 2) setScrollPosition(3 * maxScrollPositionPerSection - maxScrollPositionPerSection / 2);
    else if (index === 3) setScrollPosition(4 * maxScrollPositionPerSection - maxScrollPositionPerSection / 2);
    else if (index === 4) setScrollPosition(5 * maxScrollPositionPerSection - maxScrollPositionPerSection / 2);
  };

  // video
  const videoRef = useRef(null);
  const [loaded, setLoaded] = useState(false);

  // useEffect(() => {
  //   if (desktop) {
  //     const videoElement = videoRef.current;
  //
  //     const handleLoad = () => {
  //       setLoaded(true);
  //     };
  //
  //     videoElement.addEventListener('canplaythrough', handleLoad);
  //     return () => {
  //       videoElement.removeEventListener('canplaythrough', handleLoad);
  //     };
  //   }
  // }, [desktop]);

  const [h1Color, setH1Color] = useState('#000000');

  return (
    <section id={id} className={styles.Home}>
      {preventScroll && desktop && <ScrollHandler onScroll={handleScroll} prevent={preventScroll}></ScrollHandler>}
      {(mobile || tablet) && (
        <motion.div
          className={styles.HomeImg}
          initial={{ scale: 1 }}
          animate={{ scale: mainImageScale.get() }}
          transition={{ duration: 0.01, ease: 'easeInOut' }}
          onAnimationComplete={() => {
            setH1Color('#ffffff');
          }}
        ></motion.div>
      )}
      {desktop && !tablet && (
        <motion.div
          className={styles.HomeImg}
          initial={{ scale: 1 }}
          animate={{ scale: mainImageScale.get() }}
          transition={{ duration: 0.01, ease: 'easeInOut' }}
          onAnimationComplete={() => {
            setH1Color('#ffffff');
          }}
        ></motion.div>
      )}
      <div className={styles.HomeContent}>
        {desktop && !tablet && (
          <motion.div
            initial={{ x: -900 }}
            animate={{ x: coupleComments1.get() }}
            transition={{ duration: 0.01, ease: 'easeInOut' }}
            style={{ width: '25%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
          >
            <div>
              <p className={'didacticP'} style={{ marginBottom: '8px' }}>
                {t.homeSection4}
              </p>
              <p className={`${styles.commentCouple1P} didacticP`}>{t.homeSection5}</p>
              <img
                loading="lazy"
                src={`${process.env.CDN_PROVIDER_URL}/newassets/joeyy-lee-vJq0W_tPmDY-unsplash 1.jpg`}
                alt={'couple one whiteclover'}
                className={styles.commentCouple1Img}
              />
            </div>
          </motion.div>
        )}
        <div style={{ width: desktop ? '50%' : '100%' }}>
          <h1 className={'fridayH1'} style={{ marginBottom: '16px', color: h1Color, textShadow: '0 0 #d6d6d6' }}>
            {t.homeSection1}
          </h1>
          <h3 className={'didacticH3'} style={{ marginBottom: '32px', color: h1Color, textShadow: '0 0 #d6d6d6' }}>
            {t.homeSection2}
          </h3>
          <a href={dashboardURLRegister}>
            <span className={'didacticH4'}>{t.homeSection3}</span>
          </a>
        </div>
        {desktop && !tablet && (
          <motion.div
            initial={{ x: maxWidth + 700 }}
            animate={{ x: coupleComments2.get() }}
            transition={{ duration: 0.01, ease: 'easeInOut' }}
            style={{ width: '25%' }}
          >
            <div className={styles.commentCouple2Img}>
              <img
                loading="lazy"
                src={`${process.env.CDN_PROVIDER_URL}/newassets/hisu-lee-FTW8ADj5igs-unsplash 1.jpg`}
                alt={'couple one whiteclover'}
              />
            </div>
            <p className={`${styles.commentCouple2Name} didacticP`}>{t.homeSection6}</p>
            <p className={`${styles.commentCouple2P} didacticP`}>{t.homeSection7}</p>
          </motion.div>
        )}
      </div>
      {/*<div style={{ width: '100vw', height: '100vh', position: 'relative' }}></div>*/}
    </section>
  );
};

export default HomeSection;
